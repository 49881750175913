let vouchersDownloadStarted = false;

let initVouchersDownload = () => {
  $("#vouchers-download-button").click(() => {
    if (vouchersDownloadStarted) return;
    vouchersDownloadStarted = true;

    $("#vouchers-download-button i.fa").hide();
    $("#vouchers-download-button .spinner").show();


    $.ajax({
      url: "/vouchers/downloads",
      method: "POST",
      data: jQuery.param({from_date: $("#_search_order_date_from").val(), to_date: $("#_search_order_date_to").val() }),
      dataType: "json",
      beforeSend: (jqXHR) => {
        jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (response) => {
        if (response.status == "ok") {
          setTimeout(() => pollVouchersFileStatus(response.token), 2000);
        }
      }
    })
  })
}

let pollVouchersFileStatus = (token) => {
  $.ajax({
    url: `/vouchers/downloads/${token}`,
    method: "GET",
    dataType: "json",
    success: function (response) {
      if (response.status == "processing") {
        setTimeout(() => pollVouchersFileStatus(token), 5000);
      } else if (response.status == "ready") {
        vouchersDownloadStarted = false;
        $("#vouchers-download-button .spinner").hide();
        $("#vouchers-download-button i.fa").show();
        downloadVouchers(response.url);
      }
    }
  })
}

let downloadVouchers = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

$(function() {
  initVouchersDownload();
})
