<template>
  <div :id="id" style="width:100%;height:400px;" class="scatter-plot-component"></div>
</template>
  
  <script>
  import { ref, watch } from 'vue';
  import plotly from 'plotly.js-dist';
  
  export default {
    props: {
      traces: {
        type: Array,
        required: true
      },
      xAxisTitle: {
        type: String,
        default: null,
      },
      yAxisTitle: {
        type: String,
        default: null,
      },
    },
    setup(props) {
        const randomString = () => {
          return Math.random().toString(36).substring(7);
        }
        const id = randomString();
        var layout = {
            legend: {
                y: 0.5,
                // traceorder: 'reversed',
                font: {size: 14},
            },
            xaxis: {
                title: props.xAxisTitle,
                minor: {
                    dtick: 25,
                    ticks: 'outside',
                    showgrid: true,
                },
            },
            yaxis: {
                title: props.yAxisTitle,
            }
        };
        const options = {
            displayModeBar: false,
        };

        setTimeout(() => {
            plotly.newPlot(id, props.traces, layout, options);
        }, 100);

        watch(() => props.traces, (newTraces) => {
            plotly.newPlot(id, props.traces, layout, options);
        });
  
        return {
            id,
        }
    }
  }
  </script>
  
  <style lang="scss">
  .scatter-plot-component {

  }
  </style>