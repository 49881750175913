$(function() {
    var el = $('#order_add_extra_quantity')
    var errorEl = $('#order_exceeds_pay_extra_placeholder')
    var voucherRemainingQuantity = parseInt(el.attr("data-voucher-remaining-quantity")) || 0

    let triggerMessage = function() {
        if ((parseInt($(this).val()) || 0) > voucherRemainingQuantity) {
            errorEl.html(`<div class="alert alert-warning" role="alert">${errorEl.data("message")}</div>`)
        } else {
            errorEl.html("")
        }
    }

    el.on('input', triggerMessage).on('change', triggerMessage)
})
