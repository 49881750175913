$(function() {
    let setDeliveryTypeChange = function() {
        $('.delivery-type-select input[type="radio"]').change(function() {
            showNoteOrderCodes();
        })
        showNoteOrderCodes()
    }

    let setBookChefCheckboxChange = function() {
        $('input#order_book_chef').change(function() {
            bookChefCheckboxChange()
        })
        bookChefCheckboxChange(true)
    }


    let setEvents = function() {
        setDeliveryTypeChange()
        setBookChefCheckboxChange()
    }

    let showNoteOrderCodes = function() {
        let selectedDeliveryTypeEl = $('.delivery-type-select input[type="radio"]:checked')
        if ($.inArray(selectedDeliveryTypeEl.val(), ["warm", "cold"]) !== -1) {
            $("#order-codes").show()
        } else {
            $("#order-codes").hide()
        }
    }

    let bookChefCheckboxChange = function(initial = false) {
        let selectField = $('select#order_book_chef_quantity')
        if (!selectField.length) return null

        if ($('input#order_book_chef')[0].checked) {
            if (!initial) {
                selectField.find('option[value="0"]').remove()
                selectField.val("1")
                selectField.change()
            }
            selectField.show()
        } else {
            selectField.hide()
            selectField.append($('<option>', { value: 0, text: '' }))
            selectField.val("0")
            selectField.change()
        }
    }

    setEvents()
})

