$(function() {
    setOrderManagementSetCode();
    setOrderManagementSearchDate();
    setOrderManagementSearchDriverCode();
    setOrderManagementSearchPickupLocation();
})

let setOrderManagementSetCode = function() {
    $(".order-code-types > select").on("focus click",function () {
        $(this).data("previousValue", $(this).find(":selected").val())
    }).change(function(e) {
        updateCode($(this));
    }).click(function(e) {
        e.stopPropagation();
    })
}

let updateCode = function(el) {
    let codeType = el.attr("name")
    let codeValue = el.find(":selected").val()
    let previousValue = el.data("previousValue")
    let orderId = parseInt(el.attr("data-order-id")) || null
    codeValue = /^\d+$/.test(codeValue) ? parseInt(codeValue, 10) : codeValue
    let data = { orderId, codeType, codeValue }

    disableActive(true, el)
    new Promise((resolve, reject) => {
        check_restricted_time_intervals(resolve, reject, el, data, previousValue)
    }).then((proceed)=> {
        if (proceed) set_codes(proceed, el, data, previousValue)
    })
    disableActive(false, el)
}

let check_restricted_time_intervals = function(resolve, reject, el, data, previousValue) {
    return $.ajax({
        url: `/order_management/orders/${data.orderId}/internal_restrictions`,
        method: "GET",
        dataType: "json",
        data: { code_type: data.codeType, code_value: data.codeValue },
        beforeSend: function(jqXHR, settings) {
            jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(response) {
            let proceed = true
            if (response.restricted === true && !confirm(response.message)) {
                proceed = false
                el.val(previousValue)
            }
            resolve(proceed)
        },
        error: function(response) {
        }
    })
}

let set_codes = function(proceed, el, data, previousValue) {
    $.ajax({
            url: `/order_management/orders/${data.orderId}/codes`,
            method: "PUT",
            dataType: "json",
            data: { code_type: data.codeType, code_value: data.codeValue },
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (response) {
                switch(response.status) {
                    case "ok":
                        el.val(response.value)
                        break;
                    case "error":
                        el.val(previousValue)
                        let tr_element = $(`tr[data-order-id='${data.orderId}']`)
                        tr_element.append($("<div class='order-restricted-access'> </div>").text(response.message))
                        setTimeout(function() {
                            tr_element.children().last().remove();
                        }, 3000);
                        break;

                }
            },
            error: function (response) {
                el.val(previousValue)
                console.log("Error in order set codes")
            }
       }
    )
}


let disableActive = function(active, el) {
    if (active === true) {
        el.attr('disabled', true);
    } else {
        el.removeAttr('disabled');
    }
}

let setOrderManagementSearchDate = function() {
    $('form#search_order_delivery #_search_delivery_date').on("dp.change", function() {
        $('form#search_order_delivery').submit()
    })
}
let setOrderManagementSearchDriverCode = function() {
    $('ul.order-delivery button').click(function() {
        $('#_search_driver_code').val($(this).attr("data-driver-code"))
        $('form#search_order_delivery').submit()
    })
}
let setOrderManagementSearchPickupLocation = function() {
    $('ul.order-pickup-location button').click(function() {
        $('#_search_pickup_location_id').val($(this).attr("data-pickup-location-id"))
        $('form#search_form').submit()
    })
}