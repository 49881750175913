$(() => {
    if (!$('#order_delivery_type_pick_up').length) return;
    $('#order_delivery_type_pick_up, #order_delivery_type_cold, #order_delivery_type_warm').on('click', deliveryTypeChange);
    deliveryTypeChange();
    $('#order_delivery_date').on('focusout', deliveryDateChange);
    const selectedLocationId = $('#order_pickup_location_id').val();
    prevDate = $('#order_delivery_date').data('DateTimePicker').date();
    deliveryDateChange();
    $('#order_pickup_location_id').on('mousedown', deliveryPickupLocationClick);
    $('#order_pickup_location_id').on('change', deliveryPickupLocationChange);
})

const deliveryPickupLocationClick = function(_e) {
    const date = $('#order_delivery_date').data('DateTimePicker').date();
    if (!date) {
        _e.preventDefault();
        $('#order_delivery_date').trigger('focus');
    }

}

const resetPickupLocation = () => $('#order_pickup_location_id').val('').trigger('change');

const isDeliveryPickUp = () => $('#order_delivery_type_pick_up:checked').length > 0;

const deliveryTypeChange = function(_e) {
    if (isDeliveryPickUp()) {
        $('.pickup-location-container').show();
    } else {
        $('.pickup-location-container').hide();
        resetPickupLocation();
    }
}

const deliveryPickupLocationChange = function(_e) {
    const selectedLocationId = $('#order_pickup_location_id').val();

    if (selectedLocationId=="") return;

    const date = $('#order_delivery_date').data('DateTimePicker').date();
    if (!date) {
        $('#order_pickup_location_id option[value!=""]').prop('disabled', true).hide();
        return;
    }

    $.getJSON('/order_management/orders/pickup_location_max_quantity_warning', {
        date: date.toString(),
        pickup_location: selectedLocationId
    }, data => {
        const [max, warning,message] = data;
        if (warning) {
            $("#max_quantity_exceeded").text(message);
            $('#order-pickup-location-modal').modal({show: true});
        }
    });
}

let prevDate = null;
const deliveryDateChange = function(_e) {
    const selectedLocationId = $('#order_pickup_location_id').val();
    const date = $('#order_delivery_date').data('DateTimePicker').date();
    const d = date ? date.toString() : null;
    const pd = prevDate ? prevDate.toString() : null;
    const dateChanged = d != pd;
    if (dateChanged) {
        resetPickupLocation();
        prevDate = date;
    }

    if (!date) {
        $('#order_pickup_location_id option[value!=""]').prop('disabled', true).hide();
        return;
    }

    $.getJSON('/order_management/orders/pickup_location_availability', {date: date.toString()} , data => {
        Object.entries(data).forEach(entry => {
            const [id, [available, over_capacity  ]] = entry;

            let option = $(`#order_pickup_location_id option[value="${id}"]`)
            let remove = !available

            // remove if new order
            if ($("#order_data").val()=='' && over_capacity) remove = true

            option.prop('disabled', remove);

            if (remove) {
                option.hide();
            } else {
                option.show();
            }
        });
        if (!isDeliveryPickUp()) return;

        if (dateChanged) {
            $('#order_pickup_location_id').val('').trigger('change');
        }
    });
}
