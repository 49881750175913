import { createApp } from 'vue';
import axios, {isCancel, AxiosError} from 'axios';
axios.interceptors.request.use(
	config => {
        var token = document.querySelector('meta[name="csrf-token"]').content
		config.headers['X-CSRF-Token'] = token;
		return config
	},
	error => Promise.reject(error)
);

import plotly from 'plotly.js-dist';
window.plotly = plotly;

import _ from 'lodash';
window._ = _;

import ProductBoxCount from '@/ProductBoxCount.vue'

export default () => {
    document.addEventListener('DOMContentLoaded', () => {
        const containers = document.getElementsByClassName('vue-app');
        for(let i=0;i<containers.length;i++) {
            const app = createApp({})
            app.component('product-box-count', ProductBoxCount);
            app.mount(containers[i]);
        }
    })
}