$(function() {
    $(".order-approve").click(function (e) {
        let el = $(this)
        let orderId = parseInt(el.closest("tr").attr("data-order-id")) || null

        new Promise((resolve, reject) => {
            check_restricted_time_intervals(resolve, reject, el, { orderId })
        }).then((response)=> {
            if (response.restricted === true && confirm(response.message)) {
                approveOrder(el, { orderId })
            } else if (response.restricted === false ) {
                approveOrder(el, { orderId })
            }
        })

    })

    $('.orders-table.color-drivers').on('change', '.table-controls .order-code-types select[name="driver"]', function() {
        colorDrivers($(this));
    });
    $('.orders-table.color-drivers .table-controls .order-code-types select[name="driver"]').each(function() {
        colorDrivers($(this));
    });

    $('.orders-table.color-routes').on('change', '.table-controls .order-code-types select[name="route"]', function() {
        colorRoutes($(this));
    });
    $('.orders-table.color-routes .table-controls .order-code-types select[name="route"]').each(function() {
        colorRoutes($(this));
    });
})

let colorDrivers = function(selectElement) {
    let names = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18', 'C19', 'C20', 'K1', 'K2', 'K3'];
    colorCell(names, selectElement);
}

let colorRoutes = function(selectElement) {
    let names = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    colorCell(names, selectElement);
}

let colorCell = function(names, selectElement) {
    if (selectElement.val()) {
        let cell = selectElement.closest('td');
        let color = getColor(names.indexOf(selectElement.val()), names.length);
        cell.css({'background-color': color});
    }
}

let getColor = function(index, total) {
    return `hsl(${180 + index * 360 / total}, 55%, 60%)`;
}

$(function() {
    $('body').on('click', '[data-copy-text]', function() {
        let copyText = $(this).attr('data-copy-text');
        navigator.clipboard.writeText(copyText);
        notify('success', 'Kopieret til udklipsholder', copyText);
    });
})

let notify = function(type, title, message) {
    let alert = $(`<div class="alert alert-${type} fade show m-4" role="alert"><h4 class="alert-heading">${title ?? ''}</h4><div>${message ?? ''}</div></div>`);
    $('#notifications').append(alert);
    setTimeout(function() {
        alert.alert('close');
    }, 3000);
}

let check_restricted_time_intervals = function(resolve, reject, el, data) {
    return $.ajax({
        url: `/order_management/orders/${data.orderId}/internal_restrictions`,
        method: "GET",
        dataType: "json",
        data: { code_type: data.codeType, code_value: data.codeValue },
        beforeSend: function(jqXHR, settings) {
            jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(response) {
            resolve(response)
        },
        error: function(response) {
            console.log("Error in check restricted time intervals")
        }
    })
}

let approveOrder = function(el, data) {
    $.ajax({
            url: `/order_management/orders/${data.orderId}/approvals`,
            method: "POST",
            dataType: "json",
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (response, request) {
                let tr_element = $(`tr[data-order-id='${data.orderId}']`)
                switch(response.status) {
                    case "ok":
                        //Action cable should remove current row if it is within order_management/orders path
                        let link = tr_element.attr("data-redirect-after-approve-link")
                        if (link) window.location = link
                        break;
                    case "error":
                        tr_element.append($("<div class='order-restricted-access'> </div>").text(response.message))
                        setTimeout(function() {
                            tr_element.children().last().remove();
                        }, 3000);
                        break;
                }
            },
            error: function (response) {
                console.log("Error in order approve request")
            }
        }
    )
}
