$(function() {
    let locale = 'da'
    let dateOnlyFormat = 'L'
    let timeOnlyFormat = 'LT'
    let dateFormat = 'D-M-YYYY'
    let dateTimeFormat = 'D-M-YYYY HH:mm'

    $('.deliverytimepicker').datetimepicker({ locale: locale, format: timeOnlyFormat, stepping: 5, ignoreReadonly: true, showClear: true, icons: {clear: "fa fa-trash"}})
    $('.deliverytimepicker').attr("autocomplete", "off");

    setupDeliveryTimeSelect();
})

let setupDeliveryTimeSelect = function() {
    $(".delivery-time-select > input").each(function(){
        $(this).data("previousValue", $(this).val())
    });

    $(".delivery-time-select > input").on("dp.hide",function(e) {
        let element = $(this)
        let orderId = element.closest("tr").data("order-id")
        setDeliveryTime(element, orderId)
    })
}

let setDeliveryTime = function(el, orderId) {
    $.ajax({
            url: `/order_management/orders/${orderId}/delivery_time`,
            method: "PUT",
            dataType: "json",
            data: { delivery_time: el.val() },
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (response) {
                switch(response.status) {
                    case "ok":
                        el.addClass('correct')
                        el.data('previousValue',el.val())
                        setTimeout(function() {
                            el.removeClass('correct');
                        },1500);
                        break;
                    case "error":
                        el.addClass('error')
                        el.val(el.data('previousValue'))
                        setTimeout(function() {
                            el.removeClass('error');
                        },1500);
                        break;

                }
            },
            error: function (response) {
                el.addClass('error')
                el.val(el.data('previousValue'))
                setTimeout(function() {
                    el.removeClass('error');
                },1500);
                console.log("Error in order set codes")
            }
        }
    )
}
