// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require('bootstrap')
require('pc-bootstrap4-datetimepicker')
require('pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css')
require('./stylesheets/application.scss')
require('./javascripts/order_delivery_type_picker')
require('./javascripts/datepicker-initializer')
require('./javascripts/order_codes')
require('./javascripts/order_approve')
require('./javascripts/order_quantity')
require('./javascripts/order_note')
require('./javascripts/order_intervals')
require('./javascripts/order_pickup_location')
require('./javascripts/order_delivery_time')
require('./javascripts/customers_download')
require('./javascripts/vouchers_download')
require("../../../vendor/assets/javascripts/bootstrap-number-input")
require("cocoon-js")
//require("dawa-autocomplete2")
const {dawaAutocomplete} = require("dawa-autocomplete2");
require("./javascripts/dawa_search")

import initVueApp from "@/main.js"
initVueApp()

$(function () {

    $('[data-toggle="tooltip"]').tooltip()
})
