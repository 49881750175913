$(function() {
    let checkExetrnalIntervals = function(resolve, reject) {
        let orderDataEl = $("#order_data")
        let orderDeliveryDateEl = $("#order_delivery_date")
        let orderDeliveryTimeFromEl = $("#order_delivery_time_from")
        let orderServingTimeEl = $("#order_serving_time")
        let selectedDeliveryTypeEl = $('.delivery-type-select input[type="radio"]:checked')

        let data = {
            order_id: orderDataEl.attr("data-id"),
            delivery_type: selectedDeliveryTypeEl.val(),
            delivery_date: orderDeliveryDateEl.val(),
            delivery_time_from: orderDeliveryTimeFromEl.val(),
            serving_time: orderServingTimeEl.val()
        }

        return $.ajax({
            url: `/order_management/orders/external_restrictions`,
            data: data,
            method: "GET",
            dataType: "json",
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function(response) {
                resolve(response)
            },
            error: function(response) {
                console.log("Error in check restricted time intervals")
            }
        })
    }

    let setUpTimeIntervalsCheck = function() {
        let el = $('#order_delivery_date')
        el.on("dp.change", function() {
            new Promise((resolve, reject) => {
                checkExetrnalIntervals(resolve, reject)
            }).then((response)=> {
                if (response.restricted === true) {
                    alert(response.message)
                }
            })
        });
    }

    let setEvents = function() {
        setUpTimeIntervalsCheck()

    }
    setEvents()

})
