let customersDownloadStarted = false;

let initCustomersDownload = () => {
  $("#customers-download-button").click(() => {
    if (customersDownloadStarted) return;
    customersDownloadStarted = true;

    $("#customers-download-button i.fa").hide();
    $("#customers-download-button .spinner").show();


    $.ajax({
      url: "/customers/downloads",
      method: "POST",
      data: jQuery.param({from_date: $("#_search_delivery_date_from").val(), to_date: $("#_search_delivery_date_to").val() }),
      dataType: "json",
      beforeSend: (jqXHR) => {
        jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (response) => {
        if (response.status == "ok") {
          setTimeout(() => pollCustomersFileStatus(response.token), 2000);
        }
      }
    })
  })
}

let pollCustomersFileStatus = (token) => {
  $.ajax({
    url: `/customers/downloads/${token}`,
    method: "GET",
    dataType: "json",
    success: function (response) {
      if (response.status == "processing") {
        setTimeout(() => pollCustomersFileStatus(token), 5000);
      } else if (response.status == "ready") {
        customersDownloadStarted = false;
        $("#customers-download-button .spinner").hide();
        $("#customers-download-button i.fa").show();
        downloadCustomers(response.url);
      }
    }
  })
}

let downloadCustomers = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

$(function() {
  initCustomersDownload();
})
