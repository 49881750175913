$(function() {
    setEvents();
})

let setEvents = function() {
    setDeliveryTypeSelectEvent()
    setQuantityChangeEvent()
}

let setQuantityChangeEvent = function() {
    $('#order_quantity').change(function() {
        setDeliveryTypes();
    })
    $('#order_add_extra_quantity').change(function() {
        setDeliveryTypes();
    })
    setDeliveryTypes();
}

let setDeliveryTypeSelectEvent = function() {
    $('.delivery-type-select input[type="radio"]').change(function() {
        showDeliveryTypeContent();
    })
    showDeliveryTypeContent();
}

let setDeliveryTypes = function() {
    let label_el = $('label[for="order_delivery_type_warm"]')
    let input_el = $('input[id="order_delivery_type_warm"]')

    if ($('#user_role').data("role-name") === "admin" ) {
        label_el.show()
        input_el.show()
        return
    }
    let quantity = parseInt($('#order_quantity').val() || 0, 10) + parseInt($('#order_add_extra_quantity').val() || 0, 10)
    let warmDeliveryQuantity = $('#admin_setting_warm_delivery_quantity').val();

    if (quantity >= warmDeliveryQuantity) {
        label_el.show()
        input_el.show()
    } else {
        let selected_radio_btn = $('.delivery-type-select input[type="radio"]:checked')
        if (selected_radio_btn.val() === "warm") {
            selected_radio_btn.prop("checked", false)
        }
        showDeliveryTypeContent();
        label_el.hide();
        input_el.hide();
    }
}

let showDeliveryTypeContent = function() {
    let selected_radio_btn = $('.delivery-type-select input[type="radio"]:checked')
    let deliveryTimeEl = $('.delivery-time');
    let servingTimeEl = $('.serving-time');
    let deliveryCommonInputs = $('.delivery-common-inputs .form-group:not(.required)');

    deliveryTimeEl.hide();
    servingTimeEl.hide();
    deliveryCommonInputs.hide();

    if (selected_radio_btn.val() === "warm") {
        servingTimeEl.show();
        deliveryCommonInputs.show();
    }
    if (selected_radio_btn.val() === "cold") {
        deliveryTimeEl.show();
        deliveryCommonInputs.show();
    }
}
