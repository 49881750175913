<template>
  <div class="product-box-count-component">
    <table class="table table-striped shadow" style="width: 250px;">
      <thead>
        <tr>
          <th>Kasser</th>
          <th>Kuverter</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bc, index) in boxCountsCopy" :key="bc.id">
          <td>{{ bc.boxes }}</td>
          <td>
            <input 
              type="number" class="form-control" min="0" v-model="bc.count" 
              :class="{'bg-warning text-white': bc.count !== bc.original_count}" 
              :tabindex="index + 1"
              style="width: 80px" @keyup="boxCountChangedDebounce(bc, index === boxCountsCopy.length - 1)"/>
          </td>
        </tr>
        <tr>
          <td>{{ boxCountsCopy.length + 1 }}</td>
          <td>
            <input ref="new_box_count_input"
              type="number" class="form-control" min="0" v-model="new_box_count" 
              :class="{'bg-warning text-white': new_box_count !== ''}" 
              :tabindex="boxCountsCopy.length + 1"
              style="width: 80px" @keyup="createBoxCountDebounce" @keydown.tab="createBoxCountDebounce" />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="nonEmptyBoxCounts.length > 0" class="">
      <scatter-plot 
        :traces="boxCountTraces"
        xAxisTitle="Kuverter"
        yAxisTitle="Kasser"
      ></scatter-plot>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import ScatterPlot from './components/ScatterPlot.vue';
import axios from 'axios';

const new_box_count_input = ref(null);

export default {
  components: { ScatterPlot },
  props: {
    product_id: {
      type: Number,
      required: true
    },
    box_counts: {
      type: Array,
      required: true
    }
  },
  setup(props) {
      const boxCountsCopy = ref([]);
      for (let i = 0; i < props.box_counts.length; i++) {
        const bc = props.box_counts[i];
        boxCountsCopy.value.push({
          id: bc.id,
          boxes: bc.boxes,
          count: bc.count,
          original_count: bc.count,
          prev_count: bc.count,
        });
      }
      const new_box_count = ref('');
      const prev_new_box_count = ref('');
      const nonEmptyBoxCounts = computed(() => {
        return boxCountsCopy.value.filter(bc => bc.count !== '' && bc.count > 0);
      });
      const boxCountTraces = computed(() => {
        // console.log('boxCountTraces', boxCountsCopy.value);
        const box_count_guess = [];
        if (nonEmptyBoxCounts.value.length > 0) {
          let last_box_count = nonEmptyBoxCounts.value[nonEmptyBoxCounts.value.length - 1];
          box_count_guess.push(last_box_count);
          for (let j = 0; j < 100; j++) {
            for (let i = 0; i < nonEmptyBoxCounts.value.length; i++) {
              const bc = nonEmptyBoxCounts.value[i];
              const delta = bc.count - (i > 0 ? nonEmptyBoxCounts.value[i - 1].count : 0);
              // console.log(j, i, delta);
              box_count_guess.push({
                boxes: last_box_count.boxes + 1,
                count: last_box_count.count + delta,
              });
              last_box_count = box_count_guess[box_count_guess.length - 1];
              if (last_box_count.count > 500) {
                break;
              }
            }
            if (last_box_count.count > 500) {
              break;
            }
          }
        }
        return [
          {
            y: box_count_guess.map(bc => bc.boxes),
            x: box_count_guess.map(bc => bc.count),
            mode: 'lines',
            type: 'scatter',
            name: 'Prognose',
            marker: { color: 'red' },
            line: {
              shape: 'vh',
              width: 1,
            },
          },
          {
            y: [0, ...boxCountsCopy.value.map(bc => bc.boxes)],
            x: [0, ...boxCountsCopy.value.map(bc => bc.count)],
            mode: 'lines',
            type: 'scatter',
            name: 'Indtastede',
            marker: { color: 'blue' },
            line: {
              shape: 'vh',
              width: 1,
            },
          },
        ];
      });

      const boxCountChangedDebounce = (bc, isLast) => {
        if (bc.count === bc.prev_count) {
          console.log('boxCountChangedDebounce no change');
          return;
        }
        bc.prev_count = bc.count;
        console.log('boxCountChangedDebounce change');
        return _.debounce(boxCountChanged, 1000, {
          leading: bc.count === '',
          trailing: bc.count !== '',
        })(bc, isLast);
      };

      const boxCountChanged = (bc, isLast) => {
        if (bc.count === '' || bc.count == 0) {
          console.log('boxCountChanged empty');
          if (isLast) {
            axios
              .delete('product_box_counts/' + bc.id)
              .then(response => {
                console.log('boxCountChanged delete', response);
              }).catch(error => {
                bc.count = bc.original_count;
                boxCountsCopy.value.push(bc);
                boxCountsCopy.value.sort((a, b) => a.boxes - b.boxes);
                console.log('boxCountChanged delete error', error);
              });
              boxCountsCopy.value.pop();
              new_box_count_input.value.focus();
          } else {
            bc.count = bc.original_count;
          }
          return;
        }
        if (bc.count === bc.original_count) {
          console.log('boxCountChanged no change');
          return;
        }
        console.log('boxCountChanged');
        const requestData = {
          product_id: props.product_id,
          boxes: bc.boxes,
          count: bc.count,
        };
        axios
          .put('product_box_counts/' + bc.id, requestData)
          .then(response => {
            bc.original_count = bc.count;
          });
      };

      const createBoxCountDebounce = (e) => {
        const isTab = e.key === 'Tab';
        if (new_box_count.value === prev_new_box_count.value && !isTab) {
          console.log('createBoxCountDebounce no change');
          return;
        }
        prev_new_box_count.value = new_box_count.value;
        console.log('createBoxCountDebounce change');
        return _.debounce(createBoxCount, 1000, {
          leading: isTab,
          trailing: !isTab,
        })(e);
      };

      const createBoxCount = (e) => {
        if (!e.shiftKey) {
          e.preventDefault();
        }
        if (new_box_count.value === '' || new_box_count.value == 0) {
          // new_box_count_input.value.focus();
          new_box_count.value = '';
          return;
        }
        console.log('createBoxCount');
        const requestData = {
          product_id: props.product_id,
          boxes: boxCountsCopy.value.length + 1,
          count: new_box_count.value,
        };
        const bc = {
          id: null,
          boxes: boxCountsCopy.value.length + 1,
          count: new_box_count.value,
          original_count: new_box_count.value,
          prev_count: new_box_count.value,
        };
        boxCountsCopy.value.push(bc);
        new_box_count.value = '';
        new_box_count_input.value.focus();
        axios
          .post('product_box_counts/', requestData)
          .then(response => {
            const bcNew = response.data;
            bc.id = bcNew.id;
          });
      };

      setTimeout(() => {
        new_box_count_input.value.focus();
      }, 100);

      return {
        boxCountsCopy,
        nonEmptyBoxCounts,
        new_box_count,
        boxCountTraces,
        boxCountChangedDebounce,
        createBoxCountDebounce,
        new_box_count_input,
      };
  }
}
</script>

<style lang="scss">
.product-box-count-component {
  .shadow {
    box-shadow: 4px 6px 10px -4px rgba(0, 0, 0, .15)
  }
}
</style>