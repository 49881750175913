$(function() {
    setPickers();
    setDeliveryModal();
    setOrderColdDeliveryTimePicker();
    setOrderWarmDeliveryTimePicker();
    setOrderSearchDatePickers();
})

let setPickers = function() {
    let locale = 'da'
    let dateOnlyFormat = 'L'
    let timeOnlyFormat = 'LT'
    let dateFormat = 'D-M-YYYY'
    let dateTimeFormat = 'D-M-YYYY HH:mm'

    $('.datetimepicker-force-time').datetimepicker({ locale: locale, format: dateTimeFormat, icons : { time: "fa fa-clock" }})
    $('.datetimepicker').datetimepicker({ locale: locale, format: dateFormat, useCurrent: false})

    let userRole = $('#user_role').data("role-name")
    $('.order-datetimepicker').datetimepicker({ locale: locale, format: dateFormat, useCurrent: false, minDate: userRole === 'admin' ? false : new Date()})
    $('.timepicker').datetimepicker({ locale: locale, format: timeOnlyFormat, stepping: (userRole === 'admin' || userRole === 'employee') ? 5 : 15 })
    $('input.number-input').bootstrapNumber();

    $('.datetimepicker-force-time, .order-datetimepicker, .datetimepicker, .timepicker').attr("autocomplete", "off");
}

let setOrderColdDeliveryTimePicker = function() {
    $('#order_delivery_time_to').prop("disabled", true)
    let el = $('#order_delivery_time_from')
    el.on("dp.change", function() {
        setOrderDeliveryTimeTo()
    });
    setOrderDeliveryTimeTo()
}

let setOrderWarmDeliveryTimePicker = function() {
    $('#order_serving_time_to').prop("disabled", true)
    let el = $('#order_serving_time')
    el.on("dp.change", function() {
        setOrderServingTimeTo()
    });
    setOrderServingTimeTo()
}

let setOrderDeliveryTimeTo = function() {
    let el = $('#order_delivery_time_to')
    if (el.length > 0) {
        let date = $('#order_delivery_time_from').data("DateTimePicker").date()
        if (date) {
            date.add(2, 'hours')
            el.data('DateTimePicker').date(date)
        }
    }
}
window.clearDatepickerFields = function() {
    const from = $('#_search_delivery_date_from')
    const to = $('#_search_delivery_date_to')
    from.val("");
    to.val("");
}

let setOrderServingTimeTo = function() {
    let el = $('#order_serving_time_to')
    if (el.length > 0) {
        let date = $('#order_serving_time').data("DateTimePicker").date()
        if (date) {
            date.add(30, 'minutes')
            el.data('DateTimePicker').date(date)
        }
    }
}

let setDeliveryModal = function() {
    $('#order_delivery_date').on("focusout", function(e) {
        let days = 8 * 24 * 60 * 60 * 1000
        if ($(this).data("DateTimePicker").date() - new Date() <= days) {
            $('#order-date-modal').modal({ show: true })
        }
    })
}

let setOrderSearchDatePickers = function() {
    const from = $('#_search_delivery_date_from')
    const to = $('#_search_delivery_date_to')
    from.on('dp.change', function(e) {
        to.data('DateTimePicker').date(e.date)
    })
    to.on('dp.change', function(e) {
        if (from.data('DateTimePicker').date() > e.date) {
            from.data('DateTimePicker').date(e.date)
        }
    })
    // Initial value for TO picker
    if (from.length && to.length && !to.data('DateTimePicker').date()) {
        to.data('DateTimePicker').date(from.data('DateTimePicker').date())
    }
}
