const {dawaAutocomplete} = require("dawa-autocomplete2");
$(function () {
    if ($("#dawa_search").length > 0) {
        dawaAutocomplete(document.getElementById("dawa_search"), {
            select: function (selected) {
                $("#order_delivery_address_attributes_zipcode").val(selected.data.postnr);
                $("#order_delivery_address_attributes_city").val(selected.data.postnrnavn);
                $.getJSON(selected.data.href, data => {
                    $("#order_delivery_address_attributes_latitude").val(data.adgangsadresse.adgangspunkt.koordinater[1]);
                    $("#order_delivery_address_attributes_longitude").val(data.adgangsadresse.adgangspunkt.koordinater[0]);
                    $("#order_delivery_address_attributes_access_latitude").val(data.adgangsadresse.vejpunkt.koordinater[1]);
                    $("#order_delivery_address_attributes_access_longitude").val(data.adgangsadresse.vejpunkt.koordinater[0]);
                });

                var parts = selected.forslagstekst.split("\n");
                parts.pop();
                var streetname = parts.join(",");

                $("#order_delivery_address_attributes_address_1").val(streetname);
            }
        });
    }
})
